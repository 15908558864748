// body
& {
	font-size: 15px;
}

// headings
&.home {
	h2 {
		font-size: $h1-font-size;
		line-height: 1.3;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// container
.container-narrow {
	max-width: 950px;
}

.container-wide {
	max-width: 1260px;
}

.container-fluid {
	max-width: 1920px;
}

// column
.column-mini {
	max-width: 330px !important;
}

.column-narrow {
	max-width: 570px !important;
}

.column-medium {
	max-width: 665px !important;
}

.column-wide {
	max-width: 760px !important;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;

	&.form-custom {
		padding: 0;
		border: none;

		.form-control-label {
			color: $gray-dark;
		}

		.form-control {
			border-color: $gray-dark;
		}
	}
}

// default-card
.default-card {
	border: none;
	border-radius: 0;

	.card-body {
		padding: 15px 0 0 0;
	}
}

// default-card-tile
.default-card-tile {
	border: none;
	border-radius: 0;

	.card-img-overlay {
		display: flex;
		flex-direction: column;
		top: auto;
		padding: 0;
		border-radius: 0;
		background: none;

		.card-tags {
			display: none;
		}

		.card-caption {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			min-height: 130px;
			margin-top: auto;
			padding: 25px 30px;
			text-shadow: none;
			background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.7) 100%);

			.card-title-link {
				margin-bottom: 0;
			}

			.card-title {
				margin-bottom: 0;
				font-size: 16px;

				@include media-breakpoint-up(sm) {
					font-size: 18px;
				}

				&:hover {
					color: $white;
				}
			}

			.card-subtitle {
				order: -1;
				margin-bottom: 0.375rem;
				font-size: 15px;
				font-weight: 400;
			}

			.card-description {
				p {
					margin-bottom: 0;
				}
			}
		}

		.card-buttons {
			display: none;
		}
	}
}

// masonry
.masonry {
	&.card-columns {
		column-gap: 4px;
		column-count: 2;

		@include media-breakpoint-up(lg) {
			column-count: 3;
		}

		@include media-breakpoint-up(xl) {
			column-count: 3;
		}

		.item {
			a {
				display: block;
				margin-bottom: 4px;

				.card {
					@extend .default-card-tile;
					display: block;
					border-radius: 3px;
					margin-bottom: 0;

					&:after {
						content: "\f002";
						font-family: "Font Awesome 5 Pro";
						z-index: 100;
						position: absolute;
						right: 20px;
						bottom: 20px;
						color: $white;
						font-size: 18px;
						font-weight: 400;
						line-height: 1;
					}
				}
			}
		}
	}
}

// filter-tags
.filter-tags {
	flex-wrap: wrap;
	margin-bottom: 6vh;
	gap: 0;

	.filter-tag {
		gap: 0;
		margin: 10px 10px 0 0;
		padding: 10px 20px;
		background: transparent;
		border: 1px solid $brown;
		color: $gray-dark;
		font-size: 15px;
		line-height: 1;

		&.active,
		&:hover {
			background: transparent;
			border-color: $red;
			color: $red;
		}
	}
}


.masonry-collection {

	.card-overlay.video::after {
		content: ""; // ::before and ::after both require content
		position: absolute;
		top: calc(50% - 40px);
		left: calc(50% - 40px);
		width: 100%;
		height: 100%;
		background-size: 80px;
		background-image: url('/images/play_button.svg');
		background-repeat: no-repeat;
	}
}

// anchor
a[id] {
	position: relative;
    top: -94px;
}
