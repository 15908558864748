// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s;

	@include media-breakpoint-up(xl) {
		background: $gray-light;
	}

	&.sticky {
		box-shadow: $shadow;

		@include media-breakpoint-down(lg) {
			background: $gray-dark;
		}
	}

	.container-fluid {
		padding: 15px;

		@include media-breakpoint-up(xl) {
			padding: 25px;
		}

		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// notice-bar
	.notice-bar {
		display: none;
		background: $white;

		&.show {
			display: block;

			.notice-bar-content {
				padding: 15px;
				text-align: center;

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					margin-bottom: 0;
					color: $red;
				}

				p:last-of-type {
					margin-bottom: 0;
				}

				.notice-bar-close {
					position: absolute;
					top: 0;
					right: 0;
					padding: 15px;

					i {
						font-size: 20px;
					}

					.notice-bar-close-title {
						display: none;
					}

					&:hover {
						color: $black;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(lg) {
			margin-right: auto;
		}

		.navbar-toggler {
			margin: 0;
			padding: 0;
			border: none;
			color: $white;
			font-size: 24px;
			transition: color 0.5s;

			i {
				font-weight: 400;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}

	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $white;
						font-size: 16px;

						&:hover {
							color: $red;
						}

						&.dropdown-toggle {
							&::after {
								content: "\f107";
								font-family: $font-awesome;
								border: none;
								margin-left: 5px;
								vertical-align: middle;
							}
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $white;

								&:hover {
									color: $red;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				>.nav-item {
					margin-right: 10px;

					>.nav-link {
						display: flex;
						color: $gray-dark;
						font-size: 16px;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-toggle {
						&::after {
							content: '\f107';
							font-family: $font-awesome;
							border: none;
							margin-left: 5px;
							vertical-align: middle;
						}
					}

					.dropdown-menu {
						margin-top: 0;
						padding: 10px;
						box-shadow: $shadow;

						.nav-item {
							font-size: 15px;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $gray-dark;

								&:hover {
									color: $red;
								}
							}

							&.active {
								.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}
	}

	// socials
	div.socials {
		ul {
			li {
				+li {
					margin-left: 20px;
				}

				a {
					color: $white;

					@include media-breakpoint-up(xl) {
						color: $gray-dark;
					}

					font-size: 24px;

					&:hover {
						color: $red;
					}

					.list-item-title {
						display: none;
					}
				}
			}
		}
	}

	// button
	div.button {
		@include media-breakpoint-up(xl) {
			margin-left: 30px;
		}

		a {
			@extend .btn;
			@extend .btn-secondary;
			margin: 0;

			@include media-breakpoint-down(lg) {
				min-width: auto !important;
				margin-left: 20px !important;
				font-size: 18px !important;
			}

			i {
				@include media-breakpoint-up(xl) {
					display: none;
				}
			}

			.list-item-title {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
		}
	}
}

// home
.header {
	@include media-breakpoint-down(lg) {
		position: fixed;
	}
}

// body
&.navbar-active {
	.header {
		background: $gray-dark;
	}
}
