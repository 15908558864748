// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	p {
		color: $black;
		font-size: 19px;
	}
}

&.service-overview {
	.lead-section {
		margin-bottom: 0;

		.container {
			max-width: 950px;
		}
	}
}

&.blog-post-detail {
	.lead-section {
		.info {
			.subtitle {
				display: none;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {

	// tiles-collection
	.tiles-collection {
		margin-top: 45px;

		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card-tile;
					}
				}
			}
		}
	}

	// masonry-collection
	.masonry-collection {
		margin: 45px 4px 0 4px;

		&.masonry {
			.card-columns {
				column-gap: 4px;
				column-count: 2;

				@include media-breakpoint-up(lg) {
					column-count: 3;
				}

				@include media-breakpoint-up(xl) {
					column-count: 4;
				}

				.item {
					.card {
						@extend .default-card-tile;
						display: block;
						margin-bottom: 4px !important;
						border-radius: 3px;

						.card-caption {

							.card-subtitle,
							.card-description {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

// outro-section
// =========================================================================
.outro-section {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 150px;
		background: $gray-dark;
	}

	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-up(xl) {
						@include make-col(8);
						padding-right: 1.5rem !important;
					}
				}

				&.two {
					@include media-breakpoint-up(xl) {
						@include make-col(4);
						padding-left: 1.5rem !important;
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;
					}
				}
			}
		}
	}
}

&.blog-post-overview {
	.bundle-overview-section {
		.card {
			.card-subtitle {
				display: none;
			}
		}
	}
}

&.service-overview {
	.bundle-overview-section {
		margin-top: 0;

		.container {
			max-width: 1260px;

			.filter-tags {
				max-width: 920px;
				margin-left: auto;
				margin-right: auto;
			}

			.collection {
				&.grid {
					.grid-items {
						.item {
							.card {
								@extend .default-card-tile;

								&:after {
									content: "\f002";
									font-family: "Font Awesome 5 Pro";
									z-index: 100;
									position: absolute;
									right: 20px;
									bottom: 20px;
									color: $white;
									font-size: 18px;
									font-weight: 400;
									line-height: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}
