// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 100px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&::before {
		content: "";
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		min-height: 150px;
		max-height: 300px;
		background: linear-gradient(180deg, rgba($black, 0.8) 0%, rgba($black, 0) 100%);
	}

	// vimeo-video
	.vimeo-video {
		height: 100vh;

		@include media-breakpoint-down(md) {
			//display: none !important;
		}

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				/* height = 100 * (9 / 16) = 56.25 */
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				/* width = 100 / (9 / 16) = 177.777777 */
				width: 177.78vh;
			}
		}
	}

	// owl-carousel
	.owl-carousel {

		.owl-nav,
		.owl-dots {
			display: none;
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 75px;
		left: 15px;
		max-width: 270px;

		@include media-breakpoint-up(xl) {
			top: 30px;
			left: 30px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}

		&.top {
			@include media-breakpoint-down(lg) {
				top: 185px;
			}
		}
	}

}
