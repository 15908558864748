// footer
.footer {
	margin-top: 6vh;

	h5 {
		@include font-size($h3-font-size);
	}

	a:not(.btn) {
		text-decoration: none !important;

		&:hover {
			text-decoration: underline !important;
		}
	}

	// footer-address
	.footer-address {
		.footer-logolink {
			.list {
				.list-item {
					+li {
						margin-left: 15px;
					}

					.link {
						font-size: 24px;

						&:hover {
							color: $red;
						}
					}
				}
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		font-size: 13px;

		.logo {
			max-width: 230px;
			margin-bottom: 30px;

			a {
				display: block;
			}
		}

		.footer-text {
			ul {
				padding-left: 0;
				list-style: none;
			}

			table {
				border: none !important;
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 3vh 0 !important;
		font-size: 13px;

		ul {
			display: flex;
			flex-direction: column;
			justify-content: center;

			@include media-breakpoint-up(md) {
				flex-flow: row wrap;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-up(md) {
				li {
					margin: 0 10px;
				}
			}
		}
	}
}
